import _ from "lodash";
import jwt_decode from "jwt-decode";
import AppSettings from "../config";

/* eslint-disable */
function getAccessToken(state: any) {
  const raw_token = localStorage.getItem("jwt.access_token") as string;
  if (!raw_token) {
    return null;
  }
  return jwt_decode(raw_token);
}
/* eslint-enable */

function isTokenExpired(state: any) {
  return _.get(getAccessToken(state), "exp", 0) * 1000 < Date.now();
}

function isLoggedIn(state: any) {
  return getAccessToken(state) && !isTokenExpired(state);
}

function isInternalUser(state: any) {
  return _.get(getAccessToken(state), "data.permissions", null) === "*";
}

function isCitizen(state: any) {
  return _.get(getAccessToken(state), "data.user.isEmployee", null) !== true;
}

function isSuperAdmin(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("super_admin");
}

function isDepartmentAdmin(state: any) {
  return _.get(getAccessToken(state), "data.roles", []).includes("crm_department_admin");
}

function isCrmAdmin(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("crm_admin");
}

function isCrmUser(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("crm_user");
}

function isCeAdmin(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("ce_admin");
}

function isCeUser(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("ce_user");
}

function isCommunicationsAdmin(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("communications_admin");
}

function isPermitsAdmin(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("permits_admin");
}

function isPermitsUser(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("permits_user");
}

function isInspectionsAdmin(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("inspections_admin");
}

function isInspectionsUser(state: any) {
  if (isInternalUser(state)) {
    return true;
  }
  return _.get(getAccessToken(state), "data.roles", []).includes("inspections_user");
}

function getUserEmail(state: any) {
  return _.get(getAccessToken(state), "data.user.email");
}

function getUser(state: any) {
  return _.get(getAccessToken(state), "data.user");
}

function getUserUsername(state: any) {
  return _.get(getAccessToken(state), "data.user.username");
}

function getUserFirstName(state: any) {
  return _.get(getAccessToken(state), "data.user.firstName");
}

function getUserLastName(state: any) {
  return _.get(getAccessToken(state), "data.user.lastName");
}

function getUserId(state: any) {
  return _.get(getAccessToken(state), "data.user.id");
}

function permissions(state: any) {
  return _.get(getAccessToken(state), "data.permissions", null);
}

function postResetPassword(state: any) {
  return state.identity.postResetPassword;
}

function postVerify(state: any) {
  return state.identity.postVerify;
}

function postSendWelcome(state: any) {
  return state.identity.postSendWelcome;
}

function postForgotPassword(state: any) {
  return state.identity.postForgotPassword;
}

function postLogin(state: any) {
  return state.identity.postLogin;
}

function postSignup(state: any) {
  return state.identity.postSignup;
}

function putProfile(state: any) {
  return state.identity.putProfile;
}

function getLegacyExchangeUrl(state: any) {
  return (site: string, remember?: boolean, redirect?: string) => {
    const decoded = getAccessToken(state);
    const phpSessionId = _.get(decoded, "data.legacy.php_session_id");
    const legacyHost = redirect?.match(/m\.((?:staging\.|preprod\.)?govoutreach\.com)/) // Is a redirect to mobile version of site
      ? AppSettings.GOGOV_WEB_URL.replace("user.", "m.")
      : AppSettings.GOGOV_WEB_URL;

    const redirectComponents = new URL(redirect || `${legacyHost}/${site}/internal.php`);

    const exchangeHost =
      redirectComponents.host === window.location.host ? new URL(legacyHost).host : redirectComponents.host;
    const encodedRedirect = encodeURIComponent(redirectComponents.href);
    return `https://${exchangeHost}/exchange/exchange.php?redirect=${encodedRedirect}&phpsessionid=${phpSessionId}&rememberme=${remember}&site=${site}`;
  };
}

function getSupportUrl(state: any) {
  return (site: string, remember: boolean | undefined, redirect?: string | undefined) => {
    const decoded = getAccessToken(state);
    const phpSessionId = _.get(decoded, "data.legacy.php_session_id");
    const redirectComponents = new URL(redirect || `${AppSettings.GOGOV_WEB_URL}/${site}/support.php`);
    const exchangeHost =
      redirectComponents.host === window.location.host
        ? new URL(AppSettings.GOGOV_WEB_URL).host
        : redirectComponents.host;
    const encodedRedirect = encodeURIComponent(redirectComponents.href);
    return `https://${exchangeHost}/exchange/exchange.php?redirect=${encodedRedirect}&phpsessionid=${phpSessionId}&rememberme=${remember}&site=${site}`;
  };
}

const getLoginHistory = (id: string) => (state: any) => {
  return state.identity.getLoginHistory[id];
};

const getUserSite = (state: any) => {
  return _.get(getAccessToken(state), "data.site.name");
};

export default {
  isInternalUser,
  isTokenExpired,
  isLoggedIn,
  isSuperAdmin,
  isCrmAdmin,
  isCrmUser,
  isCeAdmin,
  isCeUser,
  isCommunicationsAdmin,
  isPermitsAdmin,
  isPermitsUser,
  isDepartmentAdmin,
  isCitizen,
  getUserId,
  getUserEmail,
  getUserUsername,
  getUser,
  getUserFirstName,
  getUserLastName,
  getLegacyExchangeUrl,
  getSupportUrl,
  permissions,
  postResetPassword,
  postForgotPassword,
  postLogin,
  postSignup,
  postVerify,
  postSendWelcome,
  putProfile,
  getLoginHistory,
  getUserSite,
  isInspectionsAdmin,
  isInspectionsUser,
};
