import React, { PropsWithChildren, useState } from "react";
import { Popup, Form, Dropdown, Label, Button } from "semantic-ui-react";
import { useNotifySubscriptionGroups } from "../../../../hooks/useNotifySubscriptionGroups";
import { useNotifySubscriptionPreferencesUpdate } from "../../../../hooks/useNotifySubscriptionPreferencesUpdate";

export function RemoveSubscribersFromSubscriptionGroupsPopup({
  children,
  subscriberIds,
  onSubmit = () => {},
}: PropsWithChildren<{ subscriberIds: number[]; onSubmit?: () => void }>) {
  const { subscriptionGroups, isSubscriptionGroupsLoading } = useNotifySubscriptionGroups({});
  const [selectedSubscriptionGroupIds, setSelectedSubscriptionGroupIds] = useState<number[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { editSubscriptionPreferences, isEditSubscriptionPreferencesLoading } =
    useNotifySubscriptionPreferencesUpdate();

  return (
    <Popup
      trigger={children}
      open={isPopupOpen}
      position='bottom left'
      className='tw-w-96 tw-p-5 md:tw-w-[400px]'
      flowing
      on='click'
      onOpen={() => setIsPopupOpen(true)}
      onClose={() => setIsPopupOpen(false)}
    >
      <Popup.Header className='tw-flex tw-flex-col'>
        <span>Remove from Groups</span>
        <span className='subText tw-text-base tw-font-medium'>{subscriberIds.length} subscribers</span>
      </Popup.Header>
      <Popup.Content className='tw-flex tw-flex-col tw-gap-3'>
        <Form.Field className='tw-m-0'>
          <Dropdown
            options={
              subscriptionGroups?.map((group) => ({
                key: group.id,
                value: group.id,
                text: group.name,
              })) ?? []
            }
            placeholder='Subscription Group'
            clearable
            loading={isSubscriptionGroupsLoading}
            selectOnBlur={false}
            multiple
            search
            fluid
            selection
            value={selectedSubscriptionGroupIds}
            onChange={(_, { value }) => setSelectedSubscriptionGroupIds(value as number[])}
            renderLabel={(label) => {
              return <Label className='tw-whitespace-nowrap' content={label.text} />;
            }}
          />
        </Form.Field>
        <div className='tw-flex tw-justify-end tw-gap-2'>
          <Button className='tw-m-0' secondary content='Cancel' onClick={() => setIsPopupOpen(false)} />
          <Button
            className='tw-m-0'
            primary
            content='Remove'
            loading={isEditSubscriptionPreferencesLoading}
            onClick={async () => {
              await editSubscriptionPreferences({
                subscriberContactMethodIds: subscriberIds,
                subscriptionGroupIds: selectedSubscriptionGroupIds,
                type: "Unsubscribe",
                overwriteExistingPreference: true,
              });
              setIsPopupOpen(false);
              setSelectedSubscriptionGroupIds([]);
              onSubmit();
            }}
          />
        </div>
      </Popup.Content>
    </Popup>
  );
}
