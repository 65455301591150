import * as yup from "yup";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { Flyout } from "../../../../../../components/global_components/Flyout";
import { Accordion, Button, Form, Header, Icon, Message, Popup, Table } from "semantic-ui-react";
import { ControlledCheckbox, ControlledInput, ControlledTextArea } from "../../../../../../components/ControlledForm";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  parsedPhoneNumberAsString,
  tryParsePhoneNumber,
} from "../../../../../../components/global_components/form_components/ControlledPhoneNumber";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useNotifySubscriptionGroups } from "../../../../hooks/useNotifySubscriptionGroups";
import { groupBy, partition, uniqBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";
import PlaceholderUntilLoaded from "../../../../../../components/global_components/PlaceholderUntilLoaded";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import classNames from "classnames";
import { useNotifyBulkInviteCreate } from "../../../../hooks/useNotifyBulkInviteCreate";
import { useToasts } from "react-toast-notifications";

type FormType = {
  recipients: string;
  manualSubscriptionGroups: { subscriptionGroupId: number; value: boolean }[];
  autoSubscriptionGroups: { subscriptionGroupId: number; value: boolean }[];
  resendInvitations: boolean;
  resendOptedOut: boolean;
  autoEnroll: {
    value: boolean;
    justification: string | null;
  };
};

export function InviteSubscribersFlyout({ open, onClose: propOnClose }: { open: boolean; onClose: () => void }) {
  const { addToast } = useToasts();
  const [keepFlyoutOpen, setKeepFlyoutOpen] = useState(false);
  const { control, watch, handleSubmit, errors, reset } = useForm<FormType>({
    defaultValues: {
      recipients: "",
      manualSubscriptionGroups: [],
      autoSubscriptionGroups: [],
      resendInvitations: false,
      resendOptedOut: false,
      autoEnroll: {
        value: false,
        justification: null,
      },
    },
  });

  const { sendBulkInvite, isSendBulkInviteLoading } = useNotifyBulkInviteCreate();

  const { subscriptionGroups, isSubscriptionGroupsLoading } = useNotifySubscriptionGroups({});
  const [autoSubscriptionGroups, manualSubscriptionGroups] = partition(
    subscriptionGroups ?? [],
    (subscriptionGroup) => subscriptionGroup.autoSubscribe,
  );

  const autoEnroll: boolean = watch("autoEnroll.value");
  const resendInvitations = watch("resendInvitations");
  const recipients = watch("recipients");
  const debouncedRecipients = useDebounce(recipients, recipients.length > 500 ? 1250 : 150);

  const parsed = useMemo(() => parseRecipients(debouncedRecipients), [debouncedRecipients]);
  const parsedByType = useMemo(() => groupBy(parsed, (recipient) => recipient?.type), [parsed]);
  const phoneCount = parsedByType.Phone?.length ?? 0;
  const emailCount = parsedByType.Email?.length ?? 0;
  const invalidCount = parsedByType.Invalid?.length ?? 0;

  const onClose = () => {
    reset();
    setKeepFlyoutOpen(false);
    propOnClose();
  };

  const onSave: SubmitHandler<FormType> = async (data) => {
    const parsed = parseRecipients(data.recipients).filter(
      (recipient): recipient is { type: "Phone" | "Email"; value: string } => recipient?.type !== "Invalid",
    );

    // By default subscribers are not subscribed to manual groups, get the groups that are different from the default
    const manualSubscriptionGroupDelta = data.manualSubscriptionGroups.filter((group) => group.value !== false);
    // By default subscribers are subscribed to auto groups, get the groups that are different from the default
    const autoSubscriptionGroupDelta = data.autoSubscriptionGroups.filter((group) => group.value !== true);

    // Smash em' together
    const subscriptionPreferences: { subscriptionGroupId: number; type: "Subscribe" | "Unsubscribe" }[] = [
      ...manualSubscriptionGroupDelta.map((group) => ({
        subscriptionGroupId: group.subscriptionGroupId,
        type: "Subscribe" as const,
      })),
      ...autoSubscriptionGroupDelta.map((group) => ({
        subscriptionGroupId: group.subscriptionGroupId,
        type: "Unsubscribe" as const,
      })),
    ];

    await sendBulkInvite({
      recipients: parsed.map((recipient) => ({
        type: recipient.type,
        identifier: recipient.value,
      })),
      subscriptionPreferences,
      options: {
        resendInvitations: data.resendInvitations,
        resendOptedOut: data.resendOptedOut,
        autoEnroll: data.autoEnroll.value ? { value: true, justification: data.autoEnroll.justification ?? "" } : null,
      },
    });

    if (data.autoEnroll.value) {
      addToast("Auto-enrolling subscribers...", { appearance: "success", autoDismiss: true });
    } else {
      addToast("Sending invitations...", { appearance: "success", autoDismiss: true });
    }

    onClose();
  };

  return (
    <Flyout open={open} onClose={onClose} disableClose={keepFlyoutOpen}>
      <Flyout.Header>
        <Header className='tw-mb-0'>Send Invites</Header>
      </Flyout.Header>
      <Flyout.Body>
        <Form>
          <Form.Field required>
            <ControlledTextArea
              control={control}
              name='recipients'
              rows={8}
              label='Recipients'
              subLabel='Enter emails and phone #s, separated by commas, new lines, or semicolons.'
              placeholder='huckleberryfinn@email.com,555-123-1234;tomsawyer@email.com 555-521-2301'
            />
          </Form.Field>
          <Table compact unstackable selectable className='tw-cursor-pointer'>
            <Table.Body>
              <Popup
                on='click'
                position='bottom left'
                disabled={emailCount === 0}
                onOpen={() => setKeepFlyoutOpen(true)}
                onClose={() => setKeepFlyoutOpen(false)}
                style={{ padding: "0" }}
                trigger={
                  <Table.Row>
                    <Table.Cell>
                      <FontAwesomeIcon className='tw-mr-2' icon={faEnvelope} />
                      <span>Emails</span>
                    </Table.Cell>
                    <Table.Cell collapsing>{emailCount}</Table.Cell>
                  </Table.Row>
                }
                content={
                  <div className='tw-max-h-96 tw-overflow-y-auto tw-overflow-x-hidden'>
                    <Table basic unstackable compact='very' className='tw-border-none tw-py-1'>
                      {parsedByType.Email?.map((recipient, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='tw-px-6'>{recipient?.value}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table>
                  </div>
                }
              />
              <Popup
                on='click'
                position='bottom left'
                disabled={phoneCount === 0}
                onOpen={() => setKeepFlyoutOpen(true)}
                onClose={() => setKeepFlyoutOpen(false)}
                style={{ padding: "0" }}
                trigger={
                  <Table.Row>
                    <Table.Cell>
                      <FontAwesomeIcon className='tw-mr-2' icon={faPhone} />
                      <span>Phone Numbers</span>
                    </Table.Cell>
                    <Table.Cell collapsing>{phoneCount}</Table.Cell>
                  </Table.Row>
                }
                content={
                  <div className='tw-max-h-96 tw-overflow-y-auto tw-overflow-x-hidden'>
                    <Table basic unstackable compact='very' className='tw-border-none tw-py-1'>
                      {parsedByType.Phone?.map((recipient, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='tw-px-6'>
                            {parsedPhoneNumberAsString(recipient?.value ?? "")}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table>
                  </div>
                }
              />
              <Popup
                on='click'
                position='bottom left'
                disabled={invalidCount === 0}
                onOpen={() => setKeepFlyoutOpen(true)}
                onClose={() => setKeepFlyoutOpen(false)}
                style={{ padding: "0" }}
                trigger={
                  <Table.Row className='tw-mr-2 tw-transition-all' warning={invalidCount > 0}>
                    <Table.Cell>
                      <Icon name='warning sign' />
                      <span>Invalid</span>
                    </Table.Cell>
                    <Table.Cell collapsing>{invalidCount}</Table.Cell>
                  </Table.Row>
                }
                content={
                  <div className='tw-max-h-96 tw-overflow-y-auto tw-overflow-x-hidden'>
                    <Table basic unstackable compact='very' className='tw-border-none tw-py-1'>
                      {parsedByType.Invalid?.map((recipient, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='tw-px-6'>{recipient?.value}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table>
                  </div>
                }
              />
            </Table.Body>
          </Table>
          <div className='tw-space-y-3'>
            <CollapsibleSection title='Manual Subscription Groups'>
              <PlaceholderUntilLoaded isLoading={isSubscriptionGroupsLoading} lineCount={3}>
                {manualSubscriptionGroups.map((subscriptionGroup, index) => (
                  <Form.Field key={subscriptionGroup.id} className='tw-m-0  tw-flex tw-items-center tw-gap-2'>
                    <ControlledCheckbox
                      control={control}
                      name={`manualSubscriptionGroups.${index}`}
                      transform={{
                        input: (value) => value?.value ?? false,
                        output: (value) => ({ subscriptionGroupId: subscriptionGroup.id, value }),
                      }}
                      className='tw-m-0'
                      label={subscriptionGroup.name}
                      defaultValue={{
                        subscriptionGroupId: subscriptionGroup.id,
                        value: false,
                      }}
                    />
                    {subscriptionGroup.visibility === "Private" && (
                      <Popup trigger={<FontAwesomeIcon icon={faLock} />} content='Private Group' inverted />
                    )}
                  </Form.Field>
                ))}
              </PlaceholderUntilLoaded>
            </CollapsibleSection>
            <CollapsibleSection title='Auto-Subscribed Groups'>
              <PlaceholderUntilLoaded isLoading={isSubscriptionGroupsLoading} lineCount={3}>
                {autoSubscriptionGroups.map((subscriptionGroup, index) => (
                  <Form.Field key={subscriptionGroup.id} className='tw-m-0  tw-flex tw-items-center tw-gap-2'>
                    <ControlledCheckbox
                      control={control}
                      name={`autoSubscriptionGroups.${index}`}
                      transform={{
                        input: (value) => value?.value ?? false,
                        output: (value) => ({ subscriptionGroupId: subscriptionGroup.id, value }),
                      }}
                      className='tw-m-0'
                      label={subscriptionGroup.name}
                      defaultValue={{
                        subscriptionGroupId: subscriptionGroup.id,
                        value: true,
                      }}
                    />
                    {subscriptionGroup.visibility === "Private" && (
                      <Popup trigger={<FontAwesomeIcon icon={faLock} />} content='Private Group' inverted />
                    )}
                  </Form.Field>
                ))}
              </PlaceholderUntilLoaded>
            </CollapsibleSection>
            <CollapsibleSection title='Advanced Settings'>
              <Form.Field className='tw-m-0'>
                <ControlledCheckbox
                  control={control}
                  name='resendInvitations'
                  className='tw-m-0'
                  label='Resend invitations if they have already been invited'
                />
              </Form.Field>
              <Form.Field className='tw-m-0 tw-flex tw-items-center tw-gap-2'>
                <ControlledCheckbox
                  control={control}
                  name='resendOptedOut'
                  className='tw-m-0'
                  label='Resend invitations if they have opted-out over 4 weeks ago'
                />
                <Icon className='-tw-mt-1' color='red' name='warning sign' />
              </Form.Field>
              <Form.Field className='tw-m-0 tw-flex tw-items-center tw-gap-2'>
                <ControlledCheckbox
                  control={control}
                  name='autoEnroll.value'
                  className='tw-m-0'
                  label='Automatically enroll to service without sending an invite'
                />
                <Icon className='-tw-mt-1' color='red' name='warning sign' />
              </Form.Field>
            </CollapsibleSection>
          </div>
          <Form.Field
            required
            className={classNames("tw-mt-3", { "tw-hidden": !autoEnroll })}
            error={!!errors.autoEnroll?.justification}
          >
            <ControlledInput
              control={control}
              name='autoEnroll.justification'
              label='Justification For Auto-Enrollment'
              subLabel='For regulatory purposes, explain why you are bypassing the enrollment process.'
              rules={{
                minLength: {
                  value: 10,
                  message: "Justification must be at least 10 characters.",
                },
              }}
              required={autoEnroll}
              errors={errors}
            />
          </Form.Field>
        </Form>
        <Message
          info
          content={
            resendInvitations
              ? "Any changes to groups will update the original invite."
              : "Subscribers who have already been invited will not be sent a new invite."
          }
        />
      </Flyout.Body>
      <Flyout.Footer>
        <div className='tw-flex tw-justify-between'>
          <Button className='button-ghost' content='Close' onClick={onClose} />
          <Button
            primary
            content='Send'
            onClick={handleSubmit(onSave)}
            disabled={isSendBulkInviteLoading || !recipients || phoneCount + emailCount === 0}
            loading={isSendBulkInviteLoading}
          />
        </div>
      </Flyout.Footer>
    </Flyout>
  );
}

function CollapsibleSection({ title, children }: PropsWithChildren<{ title: string }>) {
  const [active, setActive] = React.useState(false);
  return (
    <Accordion className='tw-border-none'>
      <Accordion.Title
        active={active}
        onClick={() => setActive((prev) => !prev)}
        className='tw-flex tw-select-none tw-items-center'
      >
        <Icon name='dropdown' />
        <Header as='h5' className='tw-m-0'>
          {title}
        </Header>
      </Accordion.Title>
      <Accordion.Content className='tw-pt-0' active={active}>
        {children}
      </Accordion.Content>
    </Accordion>
  );
}

function isValidEmail(value: string) {
  return yup.string().email().isValidSync(value);
}

function parseRecipients(rawRecipients: string) {
  const separators = [",", ";", "\n"];
  const firstSeparatorObj = separators
    .map((sep) => ({
      index: rawRecipients.indexOf(sep),
      sep,
    }))
    .filter((s) => s.index !== -1)
    .toSorted((a, b) => {
      return a.index - b.index;
    })[0];
  const firstSeparator = firstSeparatorObj?.sep as string | undefined;

  const recipientStrings = !!firstSeparator ? rawRecipients.split(firstSeparator) : [rawRecipients];

  const recipients = recipientStrings
    .map((r) => {
      const trimmed = r.trim();
      if (trimmed.length === 0) return null;

      const isEmail = isValidEmail(trimmed);
      if (isEmail) {
        return {
          type: "Email",
          value: trimmed,
        };
      }

      const { parsedPhoneNumber, regionCode } = tryParsePhoneNumber(trimmed);
      if (!regionCode || !parsedPhoneNumber) {
        return {
          type: "Invalid",
          value: trimmed,
        };
      }
      return {
        type: "Phone",
        value: parsePhoneNumber(parsedPhoneNumber, { regionCode }).number?.e164 ?? trimmed,
      };
    })
    .filter((r): r is { type: "Phone" | "Email" | "Invalid"; value: string } => r !== null);

  return uniqBy(recipients, (r) => r.value);
}
